import React, { useEffect, useState } from 'react';
import { View, Text, Pressable, Platform, Dimensions, StyleSheet, Linking } from 'react-native';

const { width } = Dimensions.get("window");
const isSmallDevice = width < 600;
const isTablet = width < 1500 && width > 600;

const StayAndPlayPopup = ({ isVisible, onClose, onCall }) => {
    const [canMakeCall, setCanMakeCall] = useState(false);

    useEffect(() => {
        // Check if the device/browser supports tel: protocol
        const checkCallCapability = () => {
            if (Platform.OS !== 'web') return true;
            const a = document.createElement('a');
            a.href = 'tel:+18144459611';
            return typeof a.protocol !== 'undefined' && a.protocol.startsWith('tel');
        };
        setCanMakeCall(checkCallCapability());
    }, []);

    const handleWebsitePress = () => {
        Linking.openURL('https://www.comfortinnsomersetpa.com/golf.htm');
    };

    if (!isVisible) return null;

    return (
        <View style={styles.overlay}>
            <View style={styles.popup}>
                <Text style={styles.headerText}>Stay and Play</Text>
                <Text style={styles.descriptionText}>
                    Please contact The Comfort Inn at (814) 445-9611 for more information on how to book.
                </Text>
                <View style={styles.buttonContainer}>
                    <View style={styles.buttonRow}>
                        {Platform.OS === 'web' && (
                            <Pressable 
                                style={[
                                    styles.button, 
                                    canMakeCall ? styles.lightButton : styles.disabledButton
                                ]} 
                                onPress={canMakeCall ? onCall : null}>
                                <Text style={[
                                    styles.buttonText,
                                    canMakeCall ? styles.lightButtonText : styles.disabledButtonText
                                ]}>Call Comfort Inn</Text>
                            </Pressable>
                        )}
                        <Pressable 
                            style={[styles.button, styles.lightButton]} 
                            onPress={handleWebsitePress}>
                            <Text style={[styles.buttonText, styles.lightButtonText]}>Go to Comfort Inn Website</Text>
                        </Pressable>
                    </View>
                    <Pressable style={[styles.button, styles.lightButton]} onPress={onClose}>
                        <Text style={[styles.buttonText, styles.lightButtonText]}>Close</Text>
                    </Pressable>
                </View>
            </View>
        </View>
    );
};

const styles = StyleSheet.create({
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1000,
    },
    popup: {
        width: isSmallDevice ? '90%' : isTablet ? '60%' : '40%',
        backgroundColor: '#214332',
        borderRadius: 25,
        padding: isSmallDevice ? 20 : 40,
        alignItems: 'center',
        shadowColor: '#000',
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
        elevation: 5,
    },
    headerText: {
        fontFamily: 'Bodoni72Smallcaps',
        fontSize: isSmallDevice ? 24 : 36,
        color: '#f7f7e0',
        marginBottom: 20,
        textAlign: 'center',
        textShadowColor: "rgba(0, 0, 0, 0.25)",
        textShadowOffset: { width: 10, height: 5 },
        textShadowRadius: 10,
    },
    descriptionText: {
        fontFamily: 'Orpheus Pro',
        fontSize: isSmallDevice ? 16 : 24,
        color: '#f7f7e0',
        marginBottom: 30,
        textAlign: 'center',
        textShadowColor: "rgba(0, 0, 0, 0.16)",
        textShadowOffset: { width: 0, height: 3 },
        textShadowRadius: 6,
    },
    buttonContainer: {
        alignItems: 'center',
        gap: 15,
    },
    buttonRow: {
        flexDirection: 'row',
        justifyContent: 'center',
        gap: 20,
        marginBottom: 10,
    },
    button: {
        paddingVertical: 10,
        paddingHorizontal: 20,
        borderRadius: 25,
        minWidth: 120,
        alignItems: 'center',
    },
    lightButton: {
        backgroundColor: '#f7f7e0',
    },
    disabledButton: {
        backgroundColor: '#15221d',
        opacity: 0.5,
    },
    buttonText: {
        fontFamily: 'Orpheus Pro',
        fontSize: isSmallDevice ? 14 : 20,
        textAlign: 'center',
    },
    lightButtonText: {
        color: '#214332',
    },
    disabledButtonText: {
        color: '#f7f7e0',
    },
});

export default StayAndPlayPopup; 