import React, { useEffect } from 'react';
import { View, Image, Pressable, Text, Dimensions, Animated } from 'react-native';
import { StyleSheet } from 'react-native';
import promoImage from '../assets/2025/New_Member_Promo.png';

const { width } = Dimensions.get('window');
const isSmallDevice = width < 600;
const isTablet = width < 1500 && width > 600;

const PromoPopup = ({ visible, onClose }) => {
  const fadeAnim = React.useRef(new Animated.Value(0)).current;
  const scaleAnim = React.useRef(new Animated.Value(0.95)).current;

  useEffect(() => {
    if (visible) {
      Animated.parallel([
        Animated.timing(fadeAnim, {
          toValue: 1,
          duration: 800,
          useNativeDriver: true,
        }),
        Animated.spring(scaleAnim, {
          toValue: 1,
          friction: 12,
          tension: 30,
          useNativeDriver: true,
        })
      ]).start();
    } else {
      Animated.parallel([
        Animated.timing(fadeAnim, {
          toValue: 0,
          duration: 400,
          useNativeDriver: true,
        }),
        Animated.spring(scaleAnim, {
          toValue: 0.95,
          friction: 12,
          tension: 30,
          useNativeDriver: true,
        })
      ]).start();
    }
  }, [visible]);

  if (!visible) return null;
  
  return (
    <View style={styles.overlay}>
      <Animated.View 
        style={[
          styles.centeredView,
          {
            opacity: fadeAnim,
            transform: [{ scale: scaleAnim }]
          }
        ]}
      >
        <View style={styles.modalView}>
          <Pressable 
            style={styles.closeButton}
            onPress={onClose}
          >
            <Text style={styles.closeButtonText}>×</Text>
          </Pressable>
          <View style={styles.headerContainer}>
            <Text style={styles.headerText}>2025 New Member Promotion</Text>
            <View style={styles.underline} />
          </View>
          <Image
            source={promoImage}
            style={styles.promoImage}
            resizeMode="contain"
          />
        </View>
      </Animated.View>
    </View>
  );
};

const styles = StyleSheet.create({
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.25)',
    zIndex: 9999,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backdropFilter: 'blur(25px)',
  },
  centeredView: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 20,
  },
  modalView: {
    backgroundColor: '#214332',
    borderRadius: 30,
    padding: isSmallDevice ? 20 : 40,
    alignItems: 'center',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 15,
    },
    shadowOpacity: 0.35,
    shadowRadius: 20,
    elevation: 5,
    position: 'relative',
    width: isSmallDevice ? '95%' : isTablet ? '80%' : '60%',
    maxWidth: 1200,
    maxHeight: '90vh',
    border: '1px solid rgba(247, 247, 224, 0.1)',
  },
  headerContainer: {
    marginBottom: 30,
    alignItems: 'center',
  },
  headerText: {
    fontFamily: 'Bodoni72Smallcaps',
    fontSize: isSmallDevice ? 28 : 42,
    color: '#f7f7e0',
    textAlign: 'center',
    textShadowColor: "rgba(0, 0, 0, 0.25)",
    textShadowOffset: { width: 4, height: 4 },
    textShadowRadius: 10,
    marginBottom: 10,
  },
  underline: {
    width: isSmallDevice ? 100 : 150,
    height: 2,
    backgroundColor: '#f7f7e0',
    opacity: 0.5,
    marginTop: 5,
  },
  promoImage: {
    width: '100%',
    height: 'auto',
    aspectRatio: 1,
    maxHeight: '70vh',
    borderRadius: 15,
  },
  closeButton: {
    position: 'absolute',
    right: isSmallDevice ? 10 : 20,
    top: isSmallDevice ? 10 : 20,
    width: isSmallDevice ? 35 : 40,
    height: isSmallDevice ? 35 : 40,
    borderRadius: 25,
    backgroundColor: 'rgba(247, 247, 224, 0.1)',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1,
    cursor: 'pointer',
    border: '1px solid rgba(247, 247, 224, 0.2)',
    transition: 'all 0.2s ease',
  },
  closeButtonText: {
    color: '#f7f7e0',
    fontSize: isSmallDevice ? 24 : 28,
    lineHeight: isSmallDevice ? 24 : 28,
    textAlign: 'center',
  },
});

export default PromoPopup; 