import React from "react";
import { View, Text } from "react-native";
import { StyleSheet, Dimensions } from "react-native";
import { ScrollView } from "react-native-gesture-handler";

const { width } = Dimensions.get("window");
const isSmallDevice = width < 600;
const isTablet = width < 1500 && width > 600;

const fontFamilies = {
	Bodoni72Smallcaps: "Bodoni72Smallcaps",
};

const Rates = () => {
	return (
		<View style={styles.golfContainer}>
			<Text style={styles.headerText}>Public Golf Rates</Text>

			<ScrollView>
				{/* Regular Rates */}
				<View>
					<View style={{ ...styles.row, ...styles.boldText }}>
						<Text style={{ ...styles.column, ...styles.descriptionLeft }}>Weekday 18 Holes w/cart</Text>
						<Text style={{ ...styles.column, ...styles.descriptionText }}>$55</Text>
					</View>

					<View style={{ ...styles.row, ...styles.boldText }}>
						<Text style={{ ...styles.column, ...styles.descriptionLeft }}>Weekday 9 Holes w/cart</Text>
						<Text style={{ ...styles.column, ...styles.descriptionText }}>$32</Text>
					</View>

					<View style={{ ...styles.row, ...styles.boldText }}>
						<Text style={{ ...styles.column, ...styles.descriptionLeft }}>Weekend 18 Holes w/cart</Text>
						<Text style={{ ...styles.column, ...styles.descriptionText }}>$65</Text>
					</View>

					<View style={{ ...styles.row, ...styles.boldText }}>
						<Text style={{ ...styles.column, ...styles.descriptionLeft }}>Weekend 9 Holes w/cart</Text>
						<Text style={{ ...styles.column, ...styles.descriptionText }}>$36</Text>
					</View>

					<View style={{ ...styles.row, ...styles.boldText }}>
						<Text style={{ ...styles.column, ...styles.descriptionLeft }}>18 Holes w/cart Twilight Rate</Text>
						<Text style={{ ...styles.column, ...styles.descriptionText }}>$40</Text>
					</View>

					<View style={{ ...styles.row, ...styles.boldText }}>
						<Text style={{ ...styles.column, ...styles.descriptionLeft }}>9 Holes w/cart Twilight Rate</Text>
						<Text style={{ ...styles.column, ...styles.descriptionText }}>$28</Text>
					</View>

					{/* Monday Special Section */}
					<View style={{ ...styles.row, ...styles.boldText, marginTop: 20 }}>
						<Text style={{ ...styles.column, ...styles.rowLead }}>Monday Special (Tee off before Noon)</Text>
					</View>

					<View style={{ ...styles.row, ...styles.boldText }}>
						<Text style={{ ...styles.column, ...styles.descriptionLeft }}>18 Holes w/cart</Text>
						<Text style={{ ...styles.column, ...styles.descriptionText }}>$45</Text>
					</View>

					<View style={{ ...styles.row, ...styles.boldText }}>
						<Text style={{ ...styles.column, ...styles.descriptionLeft }}>9 Holes w/cart</Text>
						<Text style={{ ...styles.column, ...styles.descriptionText }}>$30</Text>
					</View>

					{/* Replay Round Special Section */}
					<View style={{ ...styles.row, ...styles.boldText, marginTop: 20 }}>
						<Text style={{ ...styles.column, ...styles.rowLead }}>Replay Round Special</Text>
					</View>

					<View style={{ ...styles.row, ...styles.boldText }}>
						<Text style={{ ...styles.column, ...styles.descriptionLeft }}>18 Holes w/cart</Text>
						<Text style={{ ...styles.column, ...styles.descriptionText }}>$26</Text>
					</View>

					<View style={{ ...styles.row, ...styles.boldText }}>
						<Text style={{ ...styles.column, ...styles.descriptionLeft }}>9 Holes w/cart</Text>
						<Text style={{ ...styles.column, ...styles.descriptionText }}>$15</Text>
					</View>
				</View>
			</ScrollView>
		</View>
	);
};

const styles = StyleSheet.create({
	golfContainer: {
		width: isSmallDevice ? "80%" : (isTablet ? "80%" : "90%"),
		height: isSmallDevice ? "70%" : (isTablet ? 500: 500),
		backgroundColor: "#214332",
		paddingVertical: isSmallDevice ? 15 : 30,
		paddingHorizontal: isSmallDevice ? 5 : 30,
		borderRadius: isSmallDevice ? 25 : 50,
		margin: "auto",
		marginTop: isSmallDevice ? 15 : 50,
		alignItems: "center",
	},
	headerText: {
		fontFamily: fontFamilies.Bodoni72Smallcaps,
		fontWeight: "normal",
		fontSize: isSmallDevice ? 35 : 70,
		textAlign: "center",
		color: "#f7f7e0",
		textShadowColor: "rgba(0, 0, 0, 0.25)",
		textShadowOffset: { width: 10, height: 5 },
		textShadowRadius: 10,
		marginTop: isSmallDevice ? 12.5 : 25,
		lineHeight: isSmallDevice ? 15 : 30,
		marginBottom: isSmallDevice ? 25 : 50,
		zIndex: 1,
	},
	boldText: {
		fontWeight: "bold",
		fontFamily: "Orpheus Pro",
		fontSize: isSmallDevice ? 10 : 20,
		lineHeight: isSmallDevice ? 15 : 30,
		textAlign: "left",
		color: "#f7f7e0",
		textShadowColor: "rgba(0, 0, 0, .16)",
		textShadowOffset: { width: 0, height: 3 },
		textShadowRadius: 6,
		marginBottom: 5,
		paddingHorizontal: isSmallDevice ? 25 : 50,
	},
	descriptionText: {
		fontFamily: "Orpheus Pro",
		fontWeight: "normal",
		fontSize: isSmallDevice ? 10 : 20,
		lineHeight: isSmallDevice ? 15 : 30,
		textAlign: "center",
		color: "#f7f7e0",
		textShadowColor: "rgba(0, 0, 0, .16)",
		textShadowOffset: { width: 0, height: 3 },
		textShadowRadius: 6,
		marginBottom: 5,
		paddingHorizontal: isSmallDevice ? 25 : 50,
	},
	descriptionLeft: {
		fontFamily: "Orpheus Pro",
		fontWeight: "normal",
		fontSize: isSmallDevice ? 10 : 20,
		lineHeight: isSmallDevice ? 15 : 30,
		textAlign: "center",
		color: "#f7f7e0",
		textShadowColor: "rgba(0, 0, 0, .16)",
		textShadowOffset: { width: 0, height: 3 },
		textShadowRadius: 6,
		marginBottom: 5,
		paddingHorizontal: isSmallDevice ? 25 : 50,
	},
	rowLead: {
		fontWeight: "bold",
		fontFamily: "Orpheus Pro",
		fontSize: isSmallDevice ? 10 : 20,
		lineHeight: isSmallDevice ? 15 : 30,
		textAlign: "center",
		color: "#f7f7e0",
		textShadowColor: "rgba(0, 0, 0, .16)",
		textShadowOffset: { width: 0, height: 3 },
		textShadowRadius: 6,
		marginBottom: 5,
		paddingHorizontal: isSmallDevice ? 25 : 50,
		paddingTop: 25,
	},
	row: {
		flexDirection: "row",
		flex: 1,
		textAlign: "center",
		justifyContent: "center",
		alignItems: "center",
		width: "100%",
	},
	column: {
		flex: 1,
		textAlign: "center",
		justifyContent: "center",
		alignItems: "center",
		width: "100%",
	},
	bottomText: {
		fontFamily: "Orpheus Pro",
		fontWeight: "normal",
		fontSize: isSmallDevice ? 10 : 15,
		lineHeight: isSmallDevice ? 15 : 30,
		textAlign: "center",
		color: "#f7f7e0",
		textShadowColor: "rgba(0, 0, 0, .16)",
		textShadowOffset: { width: 0, height: 3 },
		textShadowRadius: 6,
		marginBottom: 5,
		paddingHorizontal: isSmallDevice ? 25 : 50,
	},
});

export default Rates;
