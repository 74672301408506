import React, { useState, useRef } from "react";
import { View, TouchableOpacity, Image, Dimensions, Pressable } from "react-native-web";
import { FontAwesome } from '@expo/vector-icons';
import { WebView } from 'react-native-webview';

const { width } = Dimensions.get("window");
const isSmallDevice = width < 600;
const isTablet = width < 1500 && width > 600;

const SocialMediaWidget = () => {
	const [showEmbed, setShowEmbed] = useState(false);
	const [facebookHovered, setFacebookHovered] = useState(false);
	const [youtubeHovered, setYoutubeHovered] = useState(false);
	const facebookPageUrl = "https://www.facebook.com/somersetcountryclub/";
	const embedTimeout = useRef(null);

	const handleIconMouseEnter = () => {
		if (embedTimeout.current) {
			clearTimeout(embedTimeout.current);
			embedTimeout.current = null;
		}
	};

	const handleIconMouseLeave = () => {
		if (!showEmbed) return;

		embedTimeout.current = setTimeout(() => {
			setShowEmbed(false);
		}, 300);
	};

	const handleModalMouseEnter = () => {
		if (embedTimeout.current) {
			clearTimeout(embedTimeout.current);
			embedTimeout.current = null;
		}
	};

	const handleModalMouseLeave = () => {
		embedTimeout.current = setTimeout(() => {
			setShowEmbed(false);
		}, 300);
	};

	return (
		<View style={styles.container}>
			{/* Facebook Icon */}
			<Pressable 
				style={styles.iconContainer} 
				onPress={() => window.open("https://www.facebook.com/somersetcountryclub/")} 
				onMouseEnter={() => {
					handleIconMouseEnter();
					setFacebookHovered(true);
				}} 
				onMouseLeave={() => {
					handleIconMouseLeave();
					setFacebookHovered(false);
				}}
			>
				<FontAwesome 
					name="facebook" 
					size={isSmallDevice ? 20 : 30} 
					color={facebookHovered ? "#1877F2" : "#f7f7e0"} 
				/>
			</Pressable>

			{/* YouTube Icon */}
			<Pressable 
				style={styles.iconContainer} 
				onPress={() => window.open("https://www.youtube.com/@SomersetCountryClub")} 
				onMouseEnter={() => {
					handleIconMouseEnter();
					setYoutubeHovered(true);
				}} 
				onMouseLeave={() => {
					handleIconMouseLeave();
					setYoutubeHovered(false);
				}}
			>
				<FontAwesome 
					name="youtube-play" 
					size={isSmallDevice ? 20 : 30} 
					color={youtubeHovered ? "#FF0000" : "#f7f7e0"} 
				/>
			</Pressable>

			{showEmbed && (
				<View style={styles.modalStyle} onMouseEnter={handleModalMouseEnter} onMouseLeave={handleModalMouseLeave}>
					<View style={styles.embedContainer}>
						<WebView
							source={{ uri: `https://www.facebook.com/plugins/page.php?href=${encodeURIComponent(facebookPageUrl)}&tabs=timeline&width=800&height=700&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId` }}
							style={{ width: 800, height: 700 }}
						/>
					</View>
				</View>
			)}
		</View>
	);
};

const styles = {
	container: {
		position: "absolute",
		bottom: isSmallDevice ? -10 : isTablet ? -10 : -10,
		right: isSmallDevice ? 0 : isTablet ? 50 : 0,
		display: "flex",
		flexDirection: "row",
		justifyContent: "center",
		padding: 25,
		backgroundColor: "transparent",
	},
	iconContainer: {
		border: "none",
		backgroundColor: "transparent",
		cursor: "pointer",
		padding: 10,
	},
	modalStyle: {
		position: "fixed",
		top: "30%",
		left: "30%",
		width: "40%",
		height: "40%",
		backgroundColor: "#214332",
		borderRadius: 50,
		padding: "20px",
		boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
		zIndex: 1000,
	},
	embedContainer: {
		width: "100%",
		height: "50%",
	},
};

export default SocialMediaWidget;
