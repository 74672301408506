import { useRef, useEffect } from 'react';
import { Animated } from 'react-native';

export const usePageAnimations = () => {
  const headerAnimation = useRef(new Animated.Value(0)).current;
  const contentAnimation = useRef(new Animated.Value(0)).current;
  const buttonsAnimation = useRef(new Animated.Value(0)).current;

  useEffect(() => {
    Animated.stagger(1000, [
      Animated.spring(headerAnimation, {
        toValue: 1,
        friction: 18,
        tension: 25,
        useNativeDriver: true,
      }),
      Animated.spring(contentAnimation, {
        toValue: 1,
        friction: 18,
        tension: 25,
        useNativeDriver: true,
      }),
      Animated.spring(buttonsAnimation, {
        toValue: 1,
        friction: 18,
        tension: 25,
        useNativeDriver: true,
      }),
    ]).start();
  }, []);

  const getHeaderStyle = () => ({
    opacity: headerAnimation,
    transform: [
      {
        translateY: headerAnimation.interpolate({
          inputRange: [0, 1],
          outputRange: [-30, 0],
        }),
      },
    ],
  });

  const getContentStyle = () => ({
    opacity: contentAnimation,
    transform: [
      {
        translateY: contentAnimation.interpolate({
          inputRange: [0, 1],
          outputRange: [30, 0],
        }),
      },
    ],
  });

  const getButtonStyle = () => ({
    opacity: buttonsAnimation,
    transform: [
      {
        translateY: buttonsAnimation.interpolate({
          inputRange: [0, 1],
          outputRange: [30, 0],
        }),
      },
    ],
  });

  return {
    getHeaderStyle,
    getContentStyle,
    getButtonStyle,
  };
}; 