import React, { useState, useEffect } from "react";
import { View, Text, ActivityIndicator } from "react-native";
import { NavigationContainer, useLinking } from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";
import Home from "../Screens/Home";
import Golf from "../Screens/Golf.jsx";
import Events from "../Screens/Events.jsx";
import Contact from "../Screens/Contact.jsx";
import Admin from "../Screens/Admin.jsx";
import Login from "../Screens/Login";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getFirestore, doc, getDoc } from "firebase/firestore";
import { app } from "../firebase";
import PromoPopup from "../Widgets/PromoPopup";

const Stack = createStackNavigator();

const linking = {
  prefixes: ["https://somersetcountryclub.com", "somersetcountryclub://", "http://localhost:19006"],
  config: {
    screens: {
      Home: "",
      Golf: "golf",
      Events: "events",
      Contact: "contact",
      Admin: "admin",
    },
  },
};

const Navigation = () => {
  const [user, setUser] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [loading, setLoading] = useState(true);
  const [initialRoute, setInitialRoute] = useState("Home");
  const [showPromo, setShowPromo] = useState(false);

  // Development mode flag - should match App.jsx
  const isDevelopment = false;

  useEffect(() => {
    const initializePromo = () => {
      try {
        const lastVisit = localStorage.getItem('lastVisit');
        const visitCount = parseInt(localStorage.getItem('visitCount') || '0');
        const lastPromoShow = localStorage.getItem('lastPromoShow');
        const currentTime = new Date().getTime();
        
        // Increment visit count
        localStorage.setItem('visitCount', (visitCount + 1).toString());
        
        // Show promo if:
        // 1. First time visitor (no lastVisit)
        // 2. Returning after 1 days
        // 3. Every 3 visits if they haven't seen it in the last 6 hours
        if (!lastVisit || 
            (currentTime - parseInt(lastVisit)) > (1 * 24 * 60 * 60 * 1000) || // 1 days
            (visitCount % 3 === 0 && (!lastPromoShow || (currentTime - parseInt(lastPromoShow)) > (6 * 60 * 60 * 1000)))) {
          console.log('Showing promo popup');
          // Add delay before showing the popup
          setTimeout(() => {
            setShowPromo(true);
          }, 3000); // 3 second delay
          localStorage.setItem('lastPromoShow', currentTime.toString());
        } else {
          console.log('Not showing promo - too soon or not enough visits');
          // setTimeout(() => {
          //   setShowPromo(true);
          // }, 3000);
        }
        
        // Update last visit time
        localStorage.setItem('lastVisit', currentTime.toString());
      } catch (error) {
        console.error('Error handling promo popup:', error);
        setShowPromo(false);
      }
    };

    // Remove the initialization delay since we have the show delay
    initializePromo();
  }, []);

  const handleClosePromo = () => {
    console.log('Closing promo popup');
    setShowPromo(false);
  };

  useEffect(() => {
    // In development mode, skip authentication
    if (isDevelopment) {
      setLoading(false);
      setIsAdmin(true);
      return;
    }

    const auth = getAuth(app);
    onAuthStateChanged(auth, async (user) => {
      setLoading(true);
      if (user) {
        const db = getFirestore(app);
        const docRef = doc(db, "users", user.uid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists() && docSnap.data().isAdmin) {
          setIsAdmin(true);
          setInitialRoute("Admin");
        } else {
          setIsAdmin(false);
          setInitialRoute("Home");
        }
        setUser(user);
      } else {
        setIsAdmin(false);
        setUser(null);
        setInitialRoute("Login");
      }
      setLoading(false);
    });
  }, []);

  if (loading) {
    return (
      <View style={{ flex: 1, justifyContent: "center", alignItems: "center", backgroundColor: "#214332" }}>
        <ActivityIndicator size="large" color="#f7f7e0" />
      </View>
    );
  }

  return (
    <>
      <PromoPopup visible={showPromo} onClose={handleClosePromo} />
      <NavigationContainer linking={linking} fallback={<Text>Loading...</Text>}>
        <Stack.Navigator initialRouteName={initialRoute}>
          <Stack.Screen name="Home" component={Home} options={{ headerShown: false }} />
          <Stack.Screen name="Golf" component={Golf} options={{ headerShown: false }} />
          <Stack.Screen name="Events" component={Events} options={{ headerShown: false }} />
          <Stack.Screen name="Contact" component={Contact} options={{ headerShown: false }} />
          {/* Conditional rendering based on authentication and admin status */}
          {(isDevelopment || (user && isAdmin)) ? (
            <Stack.Screen name="Admin" component={Admin} options={{ headerShown: false }} />
          ) : (
            <Stack.Screen name="Login" component={Login} options={{ headerShown: false }} />
          )}
        </Stack.Navigator>
      </NavigationContainer>
    </>
  );
};

export default Navigation;
