import { StyleSheet, Dimensions } from "react-native";

const { width } = Dimensions.get("window");
const isSmallDevice = width < 600;
const isTablet = width < 1500 && width > 600;

console.log(width);

const fontFamilies = {
	Bodoni72Smallcaps: "Bodoni72Smallcaps",
};

const styles = StyleSheet.create({
	body: {
		flex: 1,
		backgroundColor: "#214332",
	},
	background: {
		flex: 1,
		zIndex: 0,
	},
	section: {
		flex: 1,
		minHeight: isSmallDevice ? "100vh" : "100vh",
	},
	footer: {
		flex: 1,
		minHeight: isSmallDevice ? "10vh" : "25vh",
	},
	titleText: {
		fontFamily: "Bodoni72Smallcaps",
		fontWeight: "Book",
		fontSize: isSmallDevice ? 80 : isTablet ? 100 : 125,
		textAlign: "center",
		color: "#f7f7e0",
		textShadowColor: "rgba(0, 0, 0, 0.75)",
		textShadowOffset: { width: 10, height: 5 },
		textShadowRadius: 10,
		marginTop: isSmallDevice ? "20%" : "10%",
		paddingHorizontal: 25,
	},
	upcomingEvents: {
		fontFamily: "Bodoni72Smallcaps",
		fontWeight: "Book",
		fontSize: isSmallDevice ? 45 : isTablet ? 80 : 110,
		textAlign: "center",
		color: "#f7f7e0",
		textShadowColor: "rgba(0, 0, 0, 0.25)",
		textShadowOffset: { width: 10, height: 5 },
		textShadowRadius: 10,
		marginTop: isSmallDevice ? "5%" : "2.5%",
		marginBottom: isSmallDevice ? "5%" : ".5%",
	},
	navBar: {
		flexDirection: "row",
		justifyContent: "center",
		alignItems: "center",
		marginTop: 20,
		position: "relative",
		zIndex: 0,
	},
	navBarText: {
		fontFamily: "Orpheus Pro",
		fontWeight: "500",
		fontSize: isSmallDevice ? 16 : 40,
		textAlign: "center",
		color: "#f7f7e0",
		textShadowColor: "rgba(0, 0, 0, .75)",
		textShadowOffset: { width: 0, height: 3 },
		textShadowRadius: 6,
		paddingHorizontal: isSmallDevice ? 10 : 50,
	},
	navBarHeader: {
		height: 65,
		backgroundColor: "#15221d",
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
		paddingLeft: 25,
		paddingRight: 25,
		position: "relative",
		zIndex: 1000,
		elevation: 10,
	},
	navBarHeaderText: {
		fontFamily: "Orpheus Pro",
		fontWeight: "500",
		fontSize: isSmallDevice ? 20 : 25,
		textAlign: "left",
		color: "#f7f7e0",
		textShadowColor: "rgba(0, 0, 0, 0.16)",
		textShadowOffset: { width: 0, height: 3 },
		textShadowRadius: 6,
		paddingRight: 20,
	},
	navLogo: {
		width: isSmallDevice ? 37 : 74,
		height: isSmallDevice ? 23 : 45,
	},
	mainContainer: {
		width: isSmallDevice ? "80%" : isTablet ? "80%" : "80%",
		height: isSmallDevice ? "80%" : isTablet ? "80%" : "65%",
		borderRadius: isSmallDevice ? 35 : 50,
		backgroundColor: "#214332",
		boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
		margin: "auto",
		marginTop: isSmallDevice ? "1%" : "1%",
		alignItems: "center",
		justifyContent: "center",
		padding: isSmallDevice ? 10 : isTablet ? 25 : 40,
	},
	mainContainerText: {
		fontFamily: "Orpheus Pro",
		fontWeight: "normal",
		fontSize: isSmallDevice ? 16 : isTablet ? 33 : 40,
		textAlign: "center",
		color: "#f7f7e0",
		textShadowColor: "rgba(0, 0, 0, 0.16)",
		textShadowOffset: { width: 0, height: 3 },
		textShadowRadius: 6,

	},
	buttonContainer: {
		width: "100%",
		flexDirection: isSmallDevice ? "column" : "row",	
		justifyContent: "space-around",
		margin: "auto",
		marginTop: isSmallDevice ? "15%" : isTablet ? "7.5%" : "2.5%",
		alignItems: "center",
	},
	button: {
		width: isSmallDevice ? "75%" : "25%",
		paddingHorizontal: isSmallDevice ? 10 : isTablet ? 15 : 15,
		paddingVertical: 12,
		borderRadius: 45,
		backgroundColor: "#224332",
		boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
		justifyContent: "center",
		alignItems: "center",
		marginHorizontal: 10,
		marginBottom: isSmallDevice ? "5%" : "0%",
	},
	buttonText: {
		fontFamily: "Orpheus Pro",
		fontWeight: "normal",
		fontSize: isSmallDevice ? 18 : isTablet ? 28 : 32,
		textAlign: "center",
		color: "#f7f7e0",
		textShadowColor: "rgba(0, 0, 0, 0.16)",
		textShadowOffset: { width: 0, height: 3 },
		textShadowRadius: 6,
	},
	showVideoButton: {
		position: "absolute",
		top: 10,
		right: 10,
		backgroundColor: "#214332",
		padding: 10,
		zIndex: 101,
		height: isSmallDevice ? 50 : 75,
		width: isSmallDevice ? 50 : 75,
		borderRadius: 100,
		flex: 1,
		justifyContent: "center",
		alignContent: "center",
		alignItems: "center",
		marginRight: isSmallDevice ? 2.5 : 10,
		marginTop: isSmallDevice ? 2.5 : 10,
		zIndex: 100,
	},
	showVideo: {
		color: "#f7f7e0",
		fontFamily: "Orpheus Pro",
		textAlign: "center",
		fontFamily: fontFamilies.Bodoni72Smallcaps,
		fontSize: isSmallDevice ? 10 : 20,
	},
	playVideoButton: {
		position: "absolute",
		top: 10,
		right: isSmallDevice ? null : 190,
		left: isSmallDevice ? 10 : null,
		backgroundColor: "#214332",
		padding: 10,
		zIndex: 101,
		height: isSmallDevice ? 50 : 75,
		width: isSmallDevice ? 50 : 75,
		borderRadius: 100,
		flex: 1,
		justifyContent: "center",
		alignContent: "center",
		alignItems: "center",
		marginRight: isSmallDevice ? 2.5 : 10,
		marginTop: isSmallDevice ? 2.5 : 10,
		zIndex: 100,
	},
	playVideoText: {
		color: "#f7f7e0",
		fontFamily: "Orpheus Pro",
		textAlign: "center",
		fontFamily: fontFamilies.Bodoni72Smallcaps,
		fontSize: isSmallDevice ? 10 : 20,
	},
	muteVideoButton: {
		position: "absolute",
		top: 10,
		right: isSmallDevice ? 65 : 100,
		backgroundColor: "#214332",
		padding: 10,
		zIndex: 101,
		height: isSmallDevice ? 50 : 75,
		width: isSmallDevice ? 50 : 75,
		borderRadius: 100,
		flex: 1,
		justifyContent: "center",
		alignContent: "center",
		alignItems: "center",
		marginRight: isSmallDevice ? 2.5 : 10,
		marginTop: isSmallDevice ? 2.5 : 10,
	},
	muteVideoText: {
		color: "#f7f7e0",
		fontFamily: "Orpheus Pro",
		textAlign: "center",
		fontFamily: fontFamilies.Bodoni72Smallcaps,
		fontSize: isSmallDevice ? 10 : 20,
	},
	socialMediaWidget: {
		position: "absolute",
		bottom: 25,
		right: 25,
	},


	messageContainer: {
		marginRight: 15, // Space between messages
	},

	messageText: {
		color: "#f7f7e0", // Light text color for contrast
		fontFamily: "Orpheus Pro",
		fontSize: isSmallDevice ? 14 : 25, // Adjust based on device size
		paddingLeft: 25
	},

	messageBoardContainer: {
		position: "absolute",
		top: -60,
		left: 0,
		right: 0,
		marginBottom: 10,
		backgroundColor: "rgba(0, 0, 0, 0.5)",
		padding: 10,
		zIndex: 100, // Ensure it's above other content
		flexDirection: "row",
		justifyContent: "flex-start",
		alignItems: "center",
		height: "5%",
	},
});
export default styles;
