import React from "react";
import { View, Text, Pressable, Linking } from "react-native";
import { ScrollView } from "react-native-gesture-handler";
import { StyleSheet, Dimensions, Animated } from "react-native";
import { useNavigation } from "@react-navigation/native";

const { width } = Dimensions.get("window");
const isSmallDevice = width < 600;
const isTablet = width < 1500 && width > 600;

const fontFamilies = {
	Bodoni72Smallcaps: "Bodoni72Smallcaps",
};

const MembershipTypes = () => {
	const navigation = useNavigation();

	const handleLinkPress = (pageName) => {
		// Check if the pageName is "Golf"
		if (pageName === "Golf") {
			// Calculate the y-coordinate of the golf section
			const yOffset = styles.golfContainer.paddingTop; // Adjust as needed

			// Scroll to the golf section
			scrollViewRef.current.scrollTo({ y: yOffset, animated: true });
		} else {
			// For other links, you can implement navigation logic here
			console.log(`Navigating to ${pageName}`);
			navigation.navigate(pageName);
		}
	};

	const handlePdfDownload = async (pdfUrl) => {
		try {
			const response = await fetch(pdfUrl);
			if (!response.ok) {
				throw new Error(`HTTP error! Status: ${response.status}`);
			}

			const blob = await response.blob();
			const url = window.URL.createObjectURL(blob);

			// Create a new anchor element
			const a = document.createElement("a");
			a.href = url;
			a.download = pdfUrl.split("/").pop(); // Set the file name
			document.body.appendChild(a);
			a.click();

			// Clean up
			window.URL.revokeObjectURL(url);
			document.body.removeChild(a);
		} catch (error) {
			console.error("Download error:", error.message);
		}
	};

	return (
		<View style={styles.row}>
			<ScrollView horizontal={true} pagingEnabled={true} showsHorizontalScrollIndicator={isSmallDevice ? true : isTablet ? false : false} style={styles.scrollViewStyle}>
				{/* Resident Membership */}
				<View style={styles.golfContainer}>
					<Text style={styles.navBarText}>Resident Membership</Text>
					<ScrollView>
						<Text style={styles.boldText}>Full Individual:</Text>
						<Text style={styles.descriptionText}>$2,388 annually ($199 monthly)</Text>
						
						<Text style={styles.boldText}>Full Family:</Text>
						<Text style={styles.descriptionText}>$3,420 annually ($285 monthly)</Text>
						<Text style={styles.descriptionText}>(spouse/partner and children under 23 living in home or in school full time)</Text>
						
						<Text style={styles.boldText}>Golf Only:</Text>
						<Text style={styles.descriptionText}>$1,980 annually ($165 monthly)</Text>
						
						<Text style={styles.boldText}>Young Adult (under 30):</Text>
						<Text style={styles.descriptionText}>$1,668 annually ($139 monthly)</Text>
						
						<Text style={styles.boldText}>Student Golf:</Text>
						<Text style={styles.descriptionText}>$485 annually</Text>
						
						<Text style={styles.boldText}>Family Social:</Text>
						<Text style={styles.descriptionText}>$516 annually ($43 monthly)</Text>
						
						<Text style={styles.boldText}>Individual Social:</Text>
						<Text style={styles.descriptionText}>$300 annually ($25 monthly)</Text>
						
						<Text style={styles.boldText}>Add Individual:</Text>
						<Text style={styles.descriptionText}>$1,056 annually ($88 monthly)</Text>
						<Text style={styles.descriptionText}>(spouse/partner, child under 23 living in home or in school full time)</Text>

						<Text style={styles.linkText1}>All memberships subject to a $15 per month Capital Improvement Fee</Text>
						<Text style={styles.linkText1}>F&B Minimums: Full Individual/Golf Only/Young Adult/Individual Social - $400, Full Family/Family Social - $600, Add Individual - $150</Text>
						<Text style={styles.linkText1}>Memberships run from April 1 to March 31</Text>

						<Pressable style={styles.button} onPress={() => handlePdfDownload("../assets/2025/2025_Membership_Application.pdf")}>
							<Text style={styles.linkText}>Resident Application</Text>
						</Pressable>
					</ScrollView>
				</View>

				{/* Non-Resident Membership */}
				<View style={styles.golfContainer}>
					<Text style={styles.navBarText}>Non-Resident Membership</Text>
					<ScrollView>
						<Text style={styles.boldText}>Golf Only:</Text>
						<Text style={styles.descriptionText}>$1,740 annually ($145 monthly)</Text>

						<Text style={styles.linkText1}>All memberships subject to a $15 per month Capital Improvement Fee</Text>
						<Text style={styles.linkText1}>F&B Minimums: Golf Only - $400</Text>
						<Text style={styles.linkText1}>Memberships run from April 1 to March 31</Text>

						<Pressable style={styles.button} onPress={() => handlePdfDownload("../assets/2025/2025_Membership_Application.pdf")}>
							<Text style={styles.linkText}>Non-Resident Application</Text>
						</Pressable>
					</ScrollView>
				</View>

				{/* Wine & Dine Membership */}
				<View style={styles.golfContainer}>
					<Text style={styles.navBarText}>Wine & Dine Membership</Text>
					<ScrollView>
						<Text style={styles.boldText}>Single Membership:</Text>
						<Text style={styles.descriptionText}>Signle Membership $103.00 annually. Joint Membership: $156.00 annually.</Text>

						<Text style={styles.linkText1}>Membership includes a $300 Annual Food & Beverage Minimum.</Text>

						<Pressable style={styles.button} onPress={() => handlePdfDownload("../assets/Wine and Dine.pdf")}>
							<Text style={styles.linkText}>Wine & Dine Application</Text>
						</Pressable>
					</ScrollView>
				</View>
			</ScrollView>
		</View>
	);
};

const styles = StyleSheet.create({
	scrollViewStyle: {
		flex: 1,
		paddingHorizontal: isSmallDevice ? 0 : isTablet ? 50 : 100,
	},
	container: {
		backgroundColor: "#214332",
		padding: isSmallDevice ? 5 : isTablet ? 10 : null,
	},
	golfContainer: {
		width: isSmallDevice ? "70%" : isTablet ? 330 : 420,
		height: isSmallDevice ? "60%" : isTablet ? 500 : 600,
		backgroundColor: "#214332",
		paddingVertical: isSmallDevice ? 15 : 30,
		paddingHorizontal: isSmallDevice ? 5 : isTablet ? 30 : 15,
		borderRadius: isSmallDevice ? 25 : 50,
		marginBottom: isSmallDevice ? 20 : 40,
		marginHorizontal: isSmallDevice ? 0 : isTablet ? 25 : 50,
		marginTop: isSmallDevice ? 150 : isTablet ? null : null,
	},
	headerText: {
		fontFamily: fontFamilies.Bodoni72Smallcaps,
		fontWeight: "normal",
		fontSize: isSmallDevice ? 35 : 70,
		textAlign: "center",
		color: "#f7f7e0",
		textShadowColor: "rgba(0, 0, 0, 0.25)",
		textShadowOffset: { width: 10, height: 5 },
		textShadowRadius: 10,
		marginTop: isSmallDevice ? 12.5 : 25,
		lineHeight: isSmallDevice ? 15 : 30,
		marginBottom: isSmallDevice ? 25 : 50,
		zIndex: 1,
	},
	descriptionText: {
		fontFamily: "Orpheus Pro",
		fontWeight: "normal",
		fontSize: isSmallDevice ? 10 : 15,
		lineHeight: isSmallDevice ? 15 : 30,
		textAlign: "left",
		color: "#f7f7e0",
		textShadowColor: "rgba(0, 0, 0, .16)",
		textShadowOffset: { width: 0, height: 3 },
		textShadowRadius: 6,
		marginBottom: 20,
		paddingHorizontal: isSmallDevice ? 25 : 50,
	},
	linkText: {
		fontFamily: "Orpheus Pro",
		fontWeight: "normal",
		fontSize: isSmallDevice ? 10 : isTablet ? 20 : 24,
		lineHeight: isSmallDevice ? 15 : 30,
		textAlign: "center",
		color: "#214332",
		textShadowColor: "rgba(0, 0, 0, .16)",
		textShadowOffset: { width: 0, height: 3 },
		textShadowRadius: 6,
		marginBottom: 5,
		paddingHorizontal: isSmallDevice ? 25 : 50,
		paddingBottom: 0,
		paddiongTop: 50,
	},
	linkText1: {
		fontFamily: "Orpheus Pro",
		fontWeight: "normal",
		fontSize: isSmallDevice ? 10 : 15,
		lineHeight: isSmallDevice ? 15 : 30,
		textAlign: "center",
		color: "#f7f7e0",
		textShadowColor: "rgba(0, 0, 0, .16)",
		textShadowOffset: { width: 0, height: 3 },
		textShadowRadius: 6,
		marginBottom: 5,
		paddingHorizontal: isSmallDevice ? 25 : 50,
		paddingBottom: 25,
	},
	navBarText: {
		fontFamily: "Orpheus Pro",
		fontWeight: "500",
		fontSize: isSmallDevice ? 25 : 40,
		textAlign: "center",
		color: "#f7f7e0",
		textShadowColor: "rgba(0, 0, 0, .75)",
		textShadowOffset: { width: 0, height: 3 },
		textShadowRadius: 6,
		paddingHorizontal: isSmallDevice ? 10 : isTablet ? 20 : 50,
		paddingBottom: 25,
		zIndex: 1,
	},
	button: {
		width: isSmallDevice ? 188 : 250,
		height: isSmallDevice ? 30 : 60,
		backgroundColor: "#f7f7e0",
		justifyContent: "center",
		alignItems: "center",
		marginTop: isSmallDevice ? 10 : 40,
		borderRadius: isSmallDevice ? 15 : 30,
		margin: "auto",
	},
	buttonText: {
		fontFamily: fontFamilies.Bodoni72Smallcaps,
		fontWeight: "bold",
		fontSize: isSmallDevice ? 12 : 20,
		lineHeight: isSmallDevice ? 15 : 30,
		textAlign: "center",
		color: "#214332",
		margin: "auto",
	},
	spacer: {
		height: isSmallDevice ? 10 : 25,
	},
	row: {
		flexDirection: "row",
		flex: 1,
		textAlign: "center",
		justifyContent: "center",
		alignItems: "center",
		width: "100%"
	},
	boldText: {
		fontWeight: "bold",
		fontFamily: "Orpheus Pro",
		fontSize: isSmallDevice ? 10 : 20,
		lineHeight: isSmallDevice ? 15 : 30,
		textAlign: "left",
		color: "#f7f7e0",
		textShadowColor: "rgba(0, 0, 0, .16)",
		textShadowOffset: { width: 0, height: 3 },
		textShadowRadius: 6,
		marginBottom: 0,
		paddingHorizontal: isSmallDevice ? 25 : 50,
	},
});

export default MembershipTypes;
